import React, { useEffect } from 'react';
import { Box, Grid } from '@mui/material';

import { getURLparam } from '../../utils/site-utils/site-utils';

import AlbumCard from '../AlbumCard/AlbumCard.component';

const AlbumList = ({
  albumListRef,
  albumListScrollPosition,
  albums,
  handleSelectAlbum,
  reverse = false,
}) => {
  const sortedAlbums = !reverse ? albums : [...albums].reverse();
  const testMode = getURLparam('testMode');

  useEffect(() => {
    albumListRef.current.scrollTop = albumListScrollPosition || 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Grid
        sx={{ px: 2 }}
        container
        alignItems='center'
        spacing={{ xs: 2, sm: 2, md: 4 }}
        justifyContent='left'
      >
        {sortedAlbums.map((album) => {
          return (
            (album.enabled || testMode === 'true') && (
              <Grid key={album.title} item xs={12} sm={6} md={4} lg={3}>
                <AlbumCard
                  handleSelectAlbum={handleSelectAlbum}
                  album={album}
                />
              </Grid>
            )
          );
        })}
      </Grid>
    </Box>
  );
};

export default AlbumList;
