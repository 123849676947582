import ReactGA from 'react-ga4';

export const handleTrackAlbumViewEvent = (album) => {
  ReactGA.event('view_album', {
    album_id: album.id,
    album_title: album.title,
    album_view_count: 1,
  });
};

export const handleTrackSongPlayEvent = (album, songTitle) => {
  ReactGA.event('song_listen', {
    song_title: songTitle,
    album_id: album.id,
    album_title: album.title,
    song_listen_count: 1,
  });
};

export const handleTrackDownload = (album) => {
  ReactGA.event('download', {
    album_title: album.title,
    album_id: album.id,
    album_download_count: 1,
  });
};
