import React, { useCallback, useEffect, useRef, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import AlbumList from '../../AlbumList/AlbumList.component';
import { ListenOverlayAlbum } from '../ListenOverlayAlbum/ListenOverlayAlbum.component';

import albums from '../../../data/albums.json';

import './ListenOverlay.scss';

export const ListenOverlay = ({
  open: isOpen,
  showListenOverlay,
  setShowListenOverlay,
}) => {
  const [album, setAlbum] = useState(null);
  const [selectedAlbumId, setSelectedAlbumId] = useState(null);
  const [albumListScrollPosition, setAlbumListScrollPosition] = useState(0);

  const albumListRef = useRef();

  const handleSelectAlbumId = useCallback((albumId) => {
    setSelectedAlbumId(null);
    setTimeout(() => {
      setSelectedAlbumId(albumId);
    }, 100);
  }, []);

  // Reset selected album on toggle
  useEffect(() => {
    setSelectedAlbumId(null);
    setAlbumListScrollPosition(0);
  }, [isOpen]);

  // Update overlay with selected album
  useEffect(() => {
    const album = albums.find((album) => album.id === selectedAlbumId);
    setAlbum(album);
  }, [selectedAlbumId]);

  const handleDeselectAlbum = () => {
    setSelectedAlbumId(null);
  };

  const handleScroll = (e) => {
    setAlbumListScrollPosition(albumListRef.current.scrollTop);
  };

  return (
    <Dialog
      fullScreen
      open={showListenOverlay}
      onClose={() => setShowListenOverlay(false)}
      sx={{ zIndex: 'auto' }}
    >
      <AppBar position='sticky'>
        <Toolbar>
          <IconButton
            edge='start'
            color='inherit'
            onClick={() => setShowListenOverlay(false)}
            aria-label='close'
          >
            <CloseIcon />
          </IconButton>
          <Typography
            sx={{
              flexGrow: 1,
              textAlign: 'center',
              textTransform: 'uppercase',
              marginLeft: '-24px',
            }}
            variant='h6'
            component='div'
          >
            {showListenOverlay && !album && 'Choose a twisted classic'}
            {showListenOverlay && album && `${album.title}`}
          </Typography>
        </Toolbar>
      </AppBar>
      {showListenOverlay && (
        <DialogContent
          className='album-list__content'
          ref={albumListRef}
          onScroll={(e) => handleScroll(e)}
          sx={{ zIndex: 'auto' }}
        >
          <AlbumList
            albumListRef={albumListRef}
            albumListScrollPosition={albumListScrollPosition}
            albums={albums}
            handleSelectAlbum={handleSelectAlbumId}
            reverse
          />
        </DialogContent>
      )}
      {showListenOverlay && album && (
        <ListenOverlayAlbum
          album={album}
          handleDeselectAlbum={handleDeselectAlbum}
        />
      )}
    </Dialog>
  );
};
