import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ReactGA from 'react-ga4';

import { initializeShortcuts } from './utils/shortcut-utils/shortcut-utils';

import App from './components/App';
import { GA_ID } from './constants';

import './css/index.css';

ReactGA.initialize(GA_ID);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <Routes>
      <Route path='/' element={<App />} />
    </Routes>
  </BrowserRouter>
);

initializeShortcuts();
