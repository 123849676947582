import React from 'react';
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { ClearButton } from '../Buttons/Buttons';

import { getAlbumDownloadLink } from '../../utils/album-utils/album-utils';
import { handleTrackDownload } from '../../utils/analytics-utils/analytics-utils';

const AlbumCard = ({ album, handleSelectAlbum }) => {
  const handleDownloadClick = (e) => {
    e.preventDefault();

    const tempAlbumDownloadLink = document.createElement('a');
    tempAlbumDownloadLink.href = getAlbumDownloadLink(album.id);
    tempAlbumDownloadLink.setAttribute('download', '');
    tempAlbumDownloadLink.setAttribute('target', '_self');
    document.body.appendChild(tempAlbumDownloadLink);
    tempAlbumDownloadLink.click();
    document.body.removeChild(tempAlbumDownloadLink);
    handleTrackDownload(album);
  };

  return (
    <Card
      sx={{ display: 'grid', bgcolor: grey[100], cursor: 'pointer' }}
      variant='outlined'
    >
      <CardMedia
        component='img'
        image={`../images/covers/${album.id}.jpg`}
        alt={`${album.title} Album Cover`}
        sx={{ width: '100%' }}
        onClick={() => handleSelectAlbum(album.id)}
      />
      <CardContent>
        <Typography
          component='div'
          fontWeight={500}
          onClick={() => handleSelectAlbum(album.id)}
        >
          {album.title}
        </Typography>
        <Typography
          sx={{ pb: 0 }}
          variant='body2'
          color='text.secondary'
          onClick={() => handleSelectAlbum(album.id)}
        >
          {album.year}
        </Typography>
      </CardContent>
      <CardActions>
        <ClearButton size='small' onClick={() => handleSelectAlbum(album.id)}>
          Stream
        </ClearButton>
        <ClearButton size='small' onClick={(e) => handleDownloadClick(e)}>
          Download
        </ClearButton>
      </CardActions>
    </Card>
  );
};

export default AlbumCard;
