export const BASE_URL = {
  DEFAULT: 'https://www.onebentchristmas.com',
  MEDIA: 'https://media.onebentchristmas.com',
};

export const PATH = {
  MUSIC: '/music/',
  DOWNLOAD: '/download/',
};

export const GA_ID = 'G-9HY351NB7Q';
