import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import { WhiteButton } from '../Buttons/Buttons';
import Div100vh from 'react-div-100vh';

const HeroSection = ({ content, setShowAlbumList }) => {
  const handleListenClick = (e) => {
    e.preventDefault();
    setShowAlbumList(true);
  };

  return (
    <Div100vh>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          minHeight: '100%',
          backgroundImage:
            'linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url(../images/assets/background-image.jpg)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <Container>
          <Typography
            styles={{ textShadow: '20px #000000' }}
            color='secondary'
            variant='h1'
            fontWeight='500'
          >
            {content.headline}
          </Typography>
          <Typography color='secondary' variant='h2'>
            {content.subheadline}
          </Typography>
          <Box sx={{ pt: 4 }}>
            <WhiteButton
              onClick={(e) => handleListenClick(e)}
              variant='contained'
              size='large'
            >
              Listen
            </WhiteButton>
          </Box>
        </Container>
      </Box>
    </Div100vh>
  );
};

export default HeroSection;
