import { BASE_URL, PATH } from '../../constants';

export const getAlbumTrackListMetadata = (album) => {
  const albumTrackListData = album.tracks.map((track, index) => {
    return {
      musicSrc: getAlbumTrackLink(album.id, track.fileName),
      name: track.title,
      singer: 'One Bent Christmas',
      cover: `../images/covers/${album.id}.jpg`,
    };
  });

  return albumTrackListData;
};

export const getAlbumTrackLink = (albumId, fileName) => {
  return `${BASE_URL.MEDIA}${PATH.MUSIC}${albumId}/${fileName}`;
};

export const getAlbumDownloadLink = (albumId) => {
  return `${BASE_URL.MEDIA}${PATH.MUSIC}${albumId}/${albumId}.zip`;
};
