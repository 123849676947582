import React, { useEffect } from 'react';
import ReactJkMusicPlayer from 'react-jinke-music-player';

import { getAlbumTrackListMetadata } from '../../../utils/album-utils/album-utils';
import {
  handleTrackAlbumViewEvent,
  handleTrackSongPlayEvent,
} from '../../../utils/analytics-utils/analytics-utils';

import 'react-jinke-music-player/assets/index.css';
import './ListenOverlayAlbum.scss';

export const ListenOverlayAlbum = ({ album, handleDeselectAlbum }) => {
  const albumTrackListMetadata = getAlbumTrackListMetadata(album);

  // Track album view
  useEffect(() => {
    handleTrackAlbumViewEvent(album);
  }, [album]);

  const handleTrackSongEnd = (currentPlayId, audioLists, audioInfo) => {
    handleTrackSongPlayEvent(album, audioInfo.name);
  };

  return (
    <ReactJkMusicPlayer
      audioLists={albumTrackListMetadata}
      mode='full'
      onModeChange={handleDeselectAlbum}
      onAudioEnded={handleTrackSongEnd}
      restartCurrentOnPrev={true}
      showDestroy={false}
      showDownload={false}
      showReload={false}
      showThemeSwitch={false}
      theme='light'
      showMediaSession
    />
  );
};
