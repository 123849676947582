import React, { useState } from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import theme from '../themes/defaultTheme';
import content from '../data/content.json';
import HeroSection from './Sections/HeroSection';
import { ListenOverlay } from './Overlays/ListenOverlay/ListenOverlay.component';

const App = () => {
  const [showListenOverlay, setShowListenOverlay] = useState(false);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <HeroSection
          setShowAlbumList={setShowListenOverlay}
          content={content.sections.hero}
        />
        <ListenOverlay
          open={showListenOverlay}
          showListenOverlay={showListenOverlay}
          setShowListenOverlay={setShowListenOverlay}
        />
      </CssBaseline>
    </ThemeProvider>
  );
};

export default App;
