import { createTheme, responsiveFontSizes } from "@mui/material"
import { grey, red } from "@mui/material/colors"

const theme = createTheme({
    typography: {
        fontFamily: ['Roboto', 'sans-serif'].join(','),
        color: grey[900]
    },
    palette: {
        primary: {
            main: red[900]
        },
        secondary: {
            main: grey[100]
        }
    }
})

export default responsiveFontSizes(theme)