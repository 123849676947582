import { styled } from '@mui/material/styles'
import { grey, red } from '@mui/material/colors'
import Button from '@mui/material/Button'

const RedButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(red[900]),
    backgroundColor: red[900],
    '&:hover': {
        backgroundColor: red[700],
    }
}))

const WhiteButton = styled(Button)(({ theme }) => ({
    fontWeight: 500,
    color: red[900],
    backgroundColor: grey[100],
    '&:hover': {
        backgroundColor: grey[300],
    }
}))

const ClearButton = styled(Button)(({
    fontWeight: 500,
    color: red[900],
    backgroundColor: 'transparent',
    '&:hover': {
        backgroundColor: grey[300],
    }
}))

export { ClearButton, RedButton, WhiteButton }